import { useState } from "react";
import { FaBloggerB } from "react-icons/fa";
import { RiKakaoTalkFill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import styles from "./Introduction.module.css";

const Introduction = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.logoBox}>
          <div className={styles.logo} />
          <div className={styles.logoText} />
        </div>
        <div className={styles.downloadButtons}>
          <div
            className={styles.appstore}
            onClick={() => {
              window.open(
                "https://apps.apple.com/kr/app/%EC%BA%A0%ED%8D%BC%EC%8A%A4%EB%A0%88%ED%84%B0/id6474706243"
              );
            }}
          />
          <div
            className={styles.playstore}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=kr.bluewavve.campusletter"
              );
            }}
          />
        </div>
        <div className={styles.intro}>
          <div className={styles.block}>
            <div>
              <span className={styles.extraBold}>캠퍼스레터</span>는 대학생들이{" "}
            </div>
            <div>
              <span className={styles.bold}>익명</span>을 기반으로{" "}
              <span className={styles.bold}>랜덤한 상대</span>를 매칭하여
            </div>
            <div>편지를 주고 받을 수 있는 서비스 입니다.</div>
          </div>
          <div className={`${styles.block} ${styles.balloon}`}>
            <div>대화 전송은 서로 번갈아가면서 한 번씩!</div>
            <div>새 편지는 하루 5번!</div>
          </div>
          <div className={styles.block}>
            <div>
              <span className={styles.extraBold}>캠퍼스레터</span>에서는
            </div>
            <div>무분별하게 메시지를 남발하는 것을 지양하고자</div>
            <div>매칭된 상대의 답장이 오기 전까지는</div>
            <div>메시지를 보낼 수 없습니다.</div>
            <div>잠시만 여유를 가져주세요 :)</div>
            <div>또한 새로운 상대방에게 작성하는 편지는</div>
            <div>
              하루 <span className={styles.bold}>5건</span>으로 제한합니다.
            </div>
          </div>
          <div className={`${styles.block} ${styles.balloon}`}>
            <div>원하는 성별, 원하는 나이대</div>
            <div>원하는 대학교 구성원에게 편지 보내기</div>
          </div>
          <div className={styles.block}>
            <div>
              <span className={styles.extraBold}>캠퍼스레터</span>에서는 가입때
              설정한 성별에 따라
            </div>
            <div>이성에게, 동성에게 혹은 성별 상관없이</div>
            <div>편지를 보낼 수 있습니다.</div>
          </div>
          <div className={styles.block}>
            <div>또한 또래에게 편지 보내는 기능을 통해</div>
            <div>자신의 나이 위 아래로 4살까지 편지를 보낼 수 있으며</div>
            <div>같은 대학교 학생에게 보내기,</div>
            <div>다른 대학 학생에게만 보내기 기능을 통해 </div>
            <div>원하는 대학교 구성원에게 편지를 보낼 수 있습니다.</div>
          </div>
        </div>
        <div className={styles.floor}>
          <FaBloggerB
            onClick={() => {
              window.open("https://blog.naver.com/campusletter");
            }}
            size={30}
            cursor="pointer"
          />
          <RiKakaoTalkFill
            onClick={() => {
              window.open("https://open.kakao.com/o/sQtFpEGf");
            }}
            size={30}
            cursor="pointer"
          />
        </div>
      </div>
      {isModalOpen
      ?
      <div className={styles.modalBackground}>
        <div className={styles.modal}>
            <div className={styles.closeButtonBox}>
              <IoCloseSharp cursor="pointer" size={50} color="rgb(180, 180, 180)" onClick={() => {setIsModalOpen(false)}}/>
            </div>
            <div className={styles.modalTitle}>공지사항</div>
            <div className={styles.block}>
              <div>알림 푸시 기능, 편리선 개선 등</div>
              <div>유저분들의 많은 요구에 따라</div>
              <div>업데이트를 하였습니다.</div>
            </div>
            <div className={styles.block}>
              <div>그래서 2023년 12월 31일부로</div>
              <div>'샤방'에서 '캠퍼스레터'로</div>
              <div>서비스명을 변경하고,</div>
              <div>기존 웹에서 앱으로 변경하며,</div>
              <div>대학 목록 추가 등 기능을 확장합니다.</div>
            </div>
            <div className={styles.block}>
              <div>'샤방'을 이용해주신 모든 분들께 감사드리며</div>
              <div>'캠퍼스레터'를 통해</div>
              <div>앞으로도 더 재미있는 서비스를</div>
              <div>제공하기 위해 노력하겠습니다.</div>
            </div>
            <div className={styles.block}></div>
            <div className={styles.block}>
              <div>캠퍼스레터(구 샤방) 운영진 일동</div>
            </div>
        </div>
      </div>
      :
      <div />
      }
    </div>
  );
};

export default Introduction;
